.headerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: var(--spacing-3);
  background-color: var(--color-rose);
  border-radius: var(--border-radius-md);
  width: 100%;
  padding: var(--spacing-3);
}

.contentWrapper {
  display: grid;
  margin-top: var(--spacing-3);
  gap: var(--spacing-2);
}

.imageWrapper {
  width: 100%;
  position: relative;
  height: 150px;
}

@media (min-width: 768px) {
  .imageWrapper {
    height: 180px;
  }
}
