
/* Base Styles */
.linkBaseStyle {
  text-decoration: underline;
  padding: 2px var(--spacing-05);
  border: 2px solid var(--color-transparent);
  border-radius: 5px;
  color: var(--color-blue);
  font-weight: var(--font-weight-regular);
  margin: calc(-1rem - 2px) calc(-0.5rem - 2px);
  outline: none;
}

.linkBaseStyle:focus-visible {
  border: 2px solid var(--color-ocean);
}

.linkBaseStyle:hover {
  background-color: rgba(0, 68, 150, 0.12);
}

/* LinkButton Styles */
.linkButton {
  composes: linkBaseStyle;
  position: relative;
  width: fit-content;
  line-height: 1.25;
  text-align: center;
  align-self: auto;
  display: inline-flex;
}

/* Icon */
.linkButton .icon {
  margin-bottom: -3px;
  width: 2rem;
  height: 2rem;
  margin-left: var(--spacing-05);
  margin-right: 0;
}

.icon .leftIcon {
  margin-left: 0;
  margin-right: var(--spacing-05);
}

/* Color Variants */
.variantBlue {
  color: var(--color-blue);
}

.variantBlue:hover {
  background-color: rgba(0, 0, 255, 0.1);
}

.variantBlue:active {
  background-color: rgba(0, 0, 255, 0.2);
}

.variantBlue:disabled {
  color: var(--color-dark-gray1);
}

.variantHallon1 {
  color: var(--color-hallon1);
}

.variantHallon1:hover {
  background-color: rgba(255, 0, 0, 0.1);
}

.variantHallon1:active {
  background-color: rgba(255, 0, 0, 0.2);
}

.variantHallon1:disabled {
  color: var(--color-dark-gray1);
}

.variantWhite {
  color: var(--color-white);
}

.variantWhite:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.variantWhite:active {
  background-color: rgba(255, 255, 255, 0.2);
}

.variantWhite:disabled {
  color: var(--color-dark-gray1);
}
