.footerBottomContainer {
  background-color: var(--color-hallon1);
  display: grid;
}

.footerBottomContainerInner {
  composes: gridLargeWrapper from '@/global/style/Layout.module.css';
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  padding-top: var(--spacing-1);
  padding-bottom: var(--spacing-3);
  margin-bottom: 0;
}

.footerBottomContainerInner.extraMarginBottom {
  margin-bottom: 150px;
}

.logoContainer {
  display: grid;
  margin: 0 auto;
}

.companyLink {
  width: min-content;
  position: relative;
  justify-self: center;
}

.companyLink::after {
  content: '';
  display: block;
  position: absolute;
  top: -8px;
  bottom: -8px;
  left: -8px;
  right: -8px;
  border-radius: var(--border-radius-sm);
  border: 2px solid transparent;
}

.companyLink:hover::after {
  background-color: var(--color-white-hover);
}

.companyLink:active::after {
  background-color: var(--color-white-active);
}

.companyLink:focus-visible::after {
  border-color: var(--color-ocean);
}

.termsAndLinksContainer {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
}

.linksContainer {
  display: grid;
  grid-template-columns: repeat(3, auto);
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
  margin-top: var(--spacing-2);
}

.socialMediaContainer {
  margin: var(--spacing-5) auto calc(var(--spacing-1) * 9) auto;
}

.socialMediaHeader {
  color: var(--color-white);
}

.socialMediaIconContainer {
  display: grid;
  grid-auto-flow: column;
  gap: var(--spacing-1);
  justify-content: center;
  align-items: center;
  margin-top: var(--spacing-1);
}

@media (min-width: 576px) {
  .footerBottomContainerInner {
    flex-direction: row;
    align-items: center;
    padding-top: var(--spacing-4);
  }

  .logoContainer {
    margin: 0;
  }

  .companyLink {
    justify-self: start;
  }

  .linksContainer {
    justify-content: flex-start;
  }

  .socialMediaContainer {
    margin: 0;
  }

  .socialMediaIconContainer {
    justify-content: start;
  }
}

@media (min-width: 768px) {
  .footerBottomContainerInner {
    padding-top: var(--spacing-5);
    padding-bottom: var(--spacing-5);
  }

  .footerBottomContainerInner.extraMarginBottom {
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .termsAndLinksContainer {
    grid-template-columns: auto auto;
    gap: var(--spacing-1);
  }

  .linksContainer {
    margin-top: 0;
    gap: var(--spacing-1);
  }
}

.link {
  composes: linkBaseStyle from '@/components/LinkLookingButton/LinkLookingButton.module.css';
  font-size: 1.4rem;
  cursor: pointer;
}
